import React from 'react'
import './expertnotice.scss'

class Expertnotice extends React.Component {
  render() {
    return (
      <div className="expertNotice">
        <div className="contentOneHeader"><span>《专家须知》</span></div>
        <p className="contentOneOne">
          <span className="paragraphTitle">一、入库专家基本条件</span><br />
          <span className="paragraphContent">（1）政治立场坚定，遵守宪法法律，学风端正、科研严谨，在自然资源、区域经济、水利能源、交通航运、生态环境等领域具有重要学术影响力，具有较高的业务素质和良好的职业道德；</span><br />
          <span className="paragraphContent">（2）愿意承担且能够胜任学会技术咨询工作，年龄不超过65岁，具有教授、研究员或同等级别技术职称的一线科技工作者；</span><br />
          <span className="paragraphContent">（3）具有科研工作经历、现任正处级职务以上（含正处级）的科技管理人员；</span><br />
          <span className="paragraphContent">（4）创新型企业与高新技术企业技术负责人可以不受技术职称限制。</span><br />
          <span className="paragraphTitle">二、入库专家主要职责</span><br />
          <span className="paragraphContent">&nbsp;&nbsp;参加学会组织的科技奖励、团体标准、成果评价、智库建设、学术交流、技术研究等有关咨询与评审工作。</span>
          {/* <span className="paragraphContent">请各专委会于2021年2月5日前将专家推荐表（格式见附件）签字盖章后报送长江技术经济学会。</span> */}
        </p>
      </div>
    )
  }
}

export default Expertnotice
