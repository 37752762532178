import React, { useEffect, useState } from 'react';
import { Modal, Button, Result } from 'antd';
import './index.scss';
import { inviteReply } from '../../service/api'

const MailNotice = () => {
  const [resData, setResData] = useState({ data: { code: null } });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [cancelModal, setCancelModal] = useState(true);
  const [params, setParams] = useState({
    id: "",
    code: "",
    agree: ""
  });

  useEffect(() => {
    let param = {
      id: "",
      code: "",
      agree: ""
    }
    const searchString = window.location.search
    if (searchString.indexOf("?") != -1) {
      const searchParams = searchString.split("?")[1]
      const paramList = searchParams.split("&")
      paramList.forEach(item => {
        const key = item.split("=")[0]
        const value = item.split("=")[1]
        param[key] = value ? value : ""
      })
    }
    setParams(param);
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
    let param = {
      idCard: params.id,
      code: params.code,
      agree: params.agree,
      projectId: params.projectId,
    }
    inviteReply(param).then((res) => {
      console.log(111, res)
      setResData(res);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCancelModal(false);
  };

  return (
    <div className="resultBox">
      {isModalOpen ? (
        <Result
          className="resultContent"
          icon=" "
          title={
            params.agree.indexOf('1') != -1 ? (
              <p>
                您确认<span className="agreeDr">同意担任</span>会议专家？
              </p>
            ) : (
              <p>
                您确认<span className="disAgreeDr">不担任</span>会议专家？
              </p>
            )
          }
          extra={
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Button onClick={handleCancel}>取消</Button>
              <Button type="primary" onClick={handleOk}>
                确认
              </Button>
            </div>
          }
        ></Result>
      ) : cancelModal ? (
        <Result
          className="resultContent"
          status={
            resData.data.code == 200
              ? 'success'
              : resData.data.code === 5002
                ? 'error'
                : resData.data.code === 5003
                  ? 'warning'
                  : resData.data.code === 5004
                    ? 'warning'
                    : resData.data.code === 5005
                      ? 'warning'
                      : 'warning'
          }
          title={
            resData.data.code == 200
              ? `您选择了${params.agree.indexOf('1') != -1 ? '同意' : '不同意'}，系统已记录`
              : resData.data.code === 5002
                ? '操作失败！'
                : resData.data.code === 5003
                  ? '操作无效！'
                  : resData.data.code === 5004
                    ? '操作超时！'
                    : resData.data.code === 5005
                      ? '操作重复！'
                      : ''
          }
          subTitle={
            resData.data.code == 200 ? (
              <p>
                点击链接
                <a href="https://signup.cjxh.org.cn/expertLogin">https://signup.cjxh.org.cn/login</a>
                跳转到长江科学技术奖评审专家系统网址
              </p>
            ) : (
              ''
            )
          }
        />
      ) : (
        <Result title="您取消了本次操作！" />
      )}
    </div>
  );
};

export default MailNotice;
