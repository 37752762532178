import React from 'react'
import {Form, Select, Input, Radio, DatePicker, Steps, Checkbox, Button, message, Modal, List, Upload, Card} from 'antd'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './RegisterProject.scss'
import {
  uploadPersonData,
  judgeAccount,
  regInsertOrg,
  getOrgByName,
  multipleUploadFile,
  delFileInDBAndLocal, getUserById, getAttachByUserAccount, uploadProjectData, getProjectUserByTel
} from '../../service/api'
import whiteLogo from '../../assets/images/whiteLogo.png'
import checkImg from '../../assets/images/check.png'
import Constitution from '../../components/constitution/constitution'
import qs from 'qs'
// import {postRequest} from "../../utils/request";
import { DebounceSelect } from "../../components/select/DebounceSelect";
import { checkPassword, getBirthdayFromIdCard, checkIdCard, getDeviceType } from '../../utils/stringUtil'
import moment from 'moment';
import {UploadOutlined} from "@ant-design/icons";
import xhdt from "../../assets/images/学会动态.png";

const { Step } = Steps;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    }
  }
};

const apliction = '我自愿加入长江技术经济学会，拥护学会章程，维护学会利益，执行学会决议，积极履行学会义务。本人承诺入会资料真实有效，如有情况，自愿承担相关责任。';
const party = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众'];
const title = ['正高级职称', '副高级职称', '中级职称', '初级职称', '无', '其他'];
const sexList = [{value: 1, label: '男'}, {value: 2, label: '女'}];
const memberTagOption = ['理事长', '常务副理事长', '副理事长', '秘书长', '常务理事', '副秘书长', '理事', '会员'];
const qualification = ['博士后', '博士研究生', '硕士研究生', '本科', '专科'];
// const IDCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
const phoneReg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
const SpaceReg = /^(?!(\s+$))/;//纯空格

class RegisterProject extends React.Component {

  formRef = React.createRef();

  state = {
    showRegisterContent: true,
    loading: false,
    registerData: {
      ID:0,
      descriptions: "无",
    },
    userData:{},
    isUpdate: false,
    orgObj: {
      label: '',
      value: ''
    },
    addOrgName: "",
    addOrgButtonFlag: false,
    showAddOrgModalFlag: false,
    nowZc: "",
    sex: "",
    deviceType: "",//设备类型
    fjList: [],//附件数组
  };

  componentDidMount() {
    let url = this.props.location.search;
    if (url) {
      let parameter = url.split('?')[1];
      let parameterValue = parameter.split('=')[1];
      // 获取此账户的值，并重新设定registerData，isUpdate改为true
      getProjectUserByTel({tel:parameterValue}).then((res) => {
        let allData = {};
        if (res.data.code == 200) {
          allData = res.data.data;
          if (allData.sex === '女') {
            allData.sex = 2
          } else {
            allData.sex = 1
          }
          if(allData.zc){
            let flag = false;
            title.forEach(item =>{if(item === allData.zc){flag = true;}});
            if(!flag){
              allData.otherZc = allData.zc;
              allData.zc = "其他";
              this.setState({nowZc:"其他"})
            }
          }
          this.setState({userDate:allData})
          let formCurrent = this.formRef.current;
          formCurrent.setFieldsValue({
            ID:allData.id,
            name:allData.name,
            sex:allData.sex,
            idCard:allData.idCard,
            zw:allData.zw,
            zc:allData.zc,
            otherZc:allData.otherZc,
            company:allData.company,
            address:allData.address,
            tel:allData.tel,
            email:allData.email,
            //descriptions:allData.descriptions
          });
        }
      })

      this.setState({
        showRegisterContent: true,
        isUpdate: true,
        deviceType: getDeviceType()
      })
    } else {
      this.setState({
        deviceType: getDeviceType()
      })
    }
  }

  handleOrgChange = (value) => {
    // console.log(`selected ：`+JSON.stringify(value));
    this.setState({
      orgObj: value
    })
  };

  showAddOrgModal = () => {
    this.setState({
      showAddOrgModalFlag: true
    })
  };

  handleAddOrgOk = () => {

    const param = qs.stringify({ 'orgName': this.state.addOrgName });
    regInsertOrg(param).then(res => {
      let data = res.data;
      if (data.code === 200) {
        message.success('新增成功，请重新搜索单位！');
        this.setState({
          showAddOrgModalFlag: false
        })
        // console.log(44, data.data);
      } else if (data.code === 4010) {
        //账号已存在
        return message.error('此单位名称已存在，请核实后再填写！')
      }
    }).catch(
      () => { return message.error('新增单位异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！') }
    );
  };

  handleAddOrgCancel = () => {
    this.setState({
      showAddOrgModalFlag: false
    })
  };

  onOrgNameChange = e => {
    this.setState({
      addOrgName: e.target.value.trim()
    })
  };


  fetchOrgList = (orgname) => {
    // console.log('fetching user', orgname);
    let param = { 'name': orgname };
    return getOrgByName(param).then(res => {
      if (res.data.data.length < 1) {
        this.setState({
          addOrgButtonFlag: true
        });
      } else {
        this.setState({
          addOrgButtonFlag: false
        });
      }
      return res.data.data.map(org => ({
        label: org.orgName,
        value: org.orgId
      }))
    })

  };

  checkAccount = e => {
    let account = e.target.value;
    if (null === account || typeof account === undefined || "undefined" === account || "" === account) return;
    // const param=JSON.stringify({account:account,type:'expertTable'})
    const param = qs.stringify({ 'account': account, 'type': 'project' });
    judgeAccount(param).then(res => {
      let data = res.data;
      if (data.code === 2008) {
        //账号已存在
        return message.error({
          content: '此身份证号已注册，请核实后再填写！',
          style: {
            marginTop: '40vh'
          }
        })
      }
    })
  };

  // 身份证号发生变化时自动复制出生日期
  onCardIdChange = () => {
    let formCurrent = this.formRef.current;
    formCurrent.setFieldsValue({
      birthday: getBirthdayFromIdCard(formCurrent.getFieldValue('idCard'))
    })
    this.setState({
      birthday: moment(formCurrent.getFieldValue('birthday')).format('YYYY-MM-DD')
    })
  }

  // 职称为其他时需要自己输入
  zcCheck = value => {
    this.setState({
      nowZc: value
    })
  };

  sexCheck = value => {
    this.setState({
      sex: value
    })
  }

  // 提交
  onFinish = e => {
    this.setState({
      loading: true
    }, () => {
      let allData = e;
      if (allData.sex === 1) {
        allData.sex = '男'
      } else {
        allData.sex = '女'
      }
      if (allData.zc == "其他") {
        allData.zc = allData.otherZc ? allData.otherZc : ""
      }
      allData.address = allData.address?.trim();
      allData.name = allData.name?.trim();
      allData.tel = allData.tel?.trim();
      allData.zw = allData.zw?.trim();
      allData.confirmStatus = '0';
      allData.company = this.state.orgObj.label;
      allData.orgId = this.state.orgObj.value;
      uploadProjectData(allData).then(res => {
        this.setState({
          loading: false
        });
        let data = res.data;
        if (data.code === 200) {
          this.setState({
            showRegisterContent: false,
          })
        } else if (data.code === 2008) {
          return message.error('此身份证号已注册，请核实后再填写！')
        } else if (data.code === 2019) {
          return message.error('此手机号已注册！')
        }else {
          return message.error('注册失败，请重新注册！')
        }
      }).catch((error) => {
        this.setState({
          loading: false
        });
        // console.log("catch", error);
        return message.error('注册异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！')
      })
    })
  };

  // 返回登录页
  backLogin = () => {
    // console.log('返回', 22222)
    this.props.history.push('/projectLogin')
  };

  //附件上传
  //文件上传完成后执行的操作
  fjHandleChange = info => {
    let fjListExtra = [...info.fileList];
    fjListExtra = fjListExtra.map(file => {
      if (file.response) {
        file.uid = file.response.uid;
        file.url = file.response.url;
        file.name = file.response.fileName;
      }
      return file;
    });

    this.setState({
      fjList: fjListExtra
    });
  };
  fjRemove = file => {
    const fjListExtra = this.state.fjList.filter(item => item.url !== file.url);
    this.setState({
      fjList: fjListExtra
    });

    const param = qs.stringify({
      'id': file.uid,
      'fileUrl': file.url
    });
    delFileInDBAndLocal(param).then().catch();
  };


  render() {
    return (
      <div className={`${this.state.deviceType == 'Browser' ? 'registerProject' : 'registerProject mobile'}`}>
        <div className="loginLog">
          <img className="logoImage" src={whiteLogo} alt="logo" />
        </div>
        <div className="projectBox" style={this.state.showRegisterContent ?
            {height: "66vh", top: "14vh"} :
            {height: "27vh", top: "36vh"}}>
          <div className={`${this.state.showRegisterContent ? '' : 'hideContent'}`}
               style={{width: "100%", height: "100%"}}>
            <div className="projectBoxTitle">基本信息</div>
            <div className="projectContent">
              <div className="contactInfo">联系人信息：杨恩 027-82926221</div>
              <div className="tableConfirm">
                <Form
                    ref={this.formRef}
                    {...formItemLayout}
                    name="register"
                    initialValues={this.state.registerData}
                    onFinish={this.onFinish}
                    scrollToFirstError
                >
                  <Form.Item
                      name="name"
                      label="姓名"
                      rules={[
                        {
                          required: true,
                          message: '请填写姓名',
                        },
                        {
                          pattern: SpaceReg,
                          message: '输入内容不能为空'
                        }
                      ]}
                  >
                    <Input className="otherInput" placeholder="请输入姓名"/>
                  </Form.Item>
                  <Form.Item
                      style={{'display': 'none'}}
                      name="ID"
                      label="id"
                  >
                    <Input disabled/>
                  </Form.Item>

                  <Form.Item
                      name="sex"
                      label="性别"
                      rules={[
                        {
                          required: true,
                          message: '请选择性别'
                        }
                      ]}
                  >
                    <Select placeholder="请选择性别">
                      {sexList.map((item, index) => {
                        return <Option value={item.value} key={index.value}>{item.label}</Option>
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                      name="idCard"
                      label="身份证号码"
                      onBlur={this.checkAccount}
                      rules={[
                        {
                          required: true,
                          message: '请填写身份证号'
                        },
                        // {
                        //   pattern: IDCardReg,
                        //   message: '请填写正确身份证号',
                        // },
                        {
                          validator: checkIdCard
                        }
                      ]}
                  >
                    <Input className="otherInput" placeholder="请输入身份证号码" onChange={this.onCardIdChange}/>
                  </Form.Item>
                  <Form.Item
                      name="zw"
                      label="职务"
                      rules={[
                        {
                          required: true,
                          message: '请输入职务',
                        }
                      ]}
                  >
                    <Input className="otherInput" placeholder="请输入职务"/>
                  </Form.Item>
                  <Form.Item
                      name="zc"
                      label="职称"
                      rules={[
                        {
                          required: true,
                          message: '请选择职称',
                        }
                      ]}
                  >
                    <Select placeholder="请选择职称" onChange={this.zcCheck}>
                      {title.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {this.state.nowZc == "其他" ?
                      <Form.Item
                          name="otherZc"
                          label="其他职称"
                          rules={[
                            {
                              required: true,
                              message: '请输入其他职称',
                            }
                          ]}
                      >
                        <Input style={{width: 150}} placeholder="若选其他，请填写"/>
                      </Form.Item>
                      : null
                  }

                  <Form.Item
                      name="company"
                      label="工作单位"
                      help="请先输入单位名称搜索选择对应单位，如未出现可新增单位"
                      rules={[
                        {
                          required: true,
                          message: '请填写工作单位',
                        }
                      ]}
                  >
                    <DebounceSelect
                        value={this.state.orgObj}
                        placeholder="搜索单位"
                        fetchOptions={this.fetchOrgList}
                        onChange={this.handleOrgChange}
                        style={{
                          width: '100%',
                        }}
                    />

                  </Form.Item>
                  {this.state.addOrgButtonFlag ?
                      <Form.Item name="orgNameAdd" label=" ">
                        没有找到对应单位？试试<Button onClick={this.showAddOrgModal}>新增单位</Button>
                      </Form.Item> : ""
                  }

                  <Modal title="新增单位" visible={this.state.showAddOrgModalFlag}
                         onOk={this.handleAddOrgOk}
                         onCancel={this.handleAddOrgCancel}
                         okText="确定"
                         cancelText="取消"
                  >
                    <Form.Item
                        className='unitName'
                        name="unitName"
                        rules={[
                          {
                            pattern: SpaceReg,
                            message: '输入内容不能为空'
                          }
                        ]}
                    >
                      <Input placeholder="输入单位名称" value={this.state.addOrgName} onChange={this.onOrgNameChange}/>
                    </Form.Item>
                  </Modal>


                  <Form.Item
                      name="address"
                      label="通讯地址"
                      rules={[
                        {
                          required: true,
                          message: '请填写通讯地址'
                        }
                      ]}
                  >
                    <Input className="otherInput" placeholder="请输入通讯地址"/>
                  </Form.Item>
                  <Form.Item
                      name="tel"
                      label="联系电话"
                      onBlur={this.checkAccount}
                      rules={[
                        {
                          required: true,
                          message: '请填写联系电话'
                        },
                        {
                          pattern: phoneReg,
                          message: '请填写正确手机号码'
                        }
                      ]}
                  >
                    <Input className="otherInput" placeholder="请输入联系电话"/>
                  </Form.Item>
                  <Form.Item
                      name="email"
                      label="联系邮箱"
                      rules={[
                        {
                          type: 'email',
                          message: '输入不是有效电子邮箱',
                        },
                        {
                          required: true,
                          message: '请填写电子邮箱',
                        }
                      ]}
                  >
                    <Input className="otherInput" placeholder="请输联系邮箱"/>
                  </Form.Item>

                  <Form.Item
                      name="pwd"
                      label="密码"
                      rules={[
                        {
                          validator: checkPassword
                        },
                        {
                          required: true,
                          message: '请填写密码!',
                        },
                      ]}
                      hasFeedback
                  >
                    <Input.Password placeholder="请输入密码"/>
                  </Form.Item>
                  <Form.Item
                      name="confirmPwd"
                      label="确认密码"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: '请确认您的密码!',
                        },
                        ({getFieldValue}) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('pwd') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('两次密码不一致!');
                          },
                        }),
                      ]}
                  >
                    <Input.Password placeholder="请输入密码"/>
                  </Form.Item>
                  <div className="buttonItem">
                    {this.state.isUpdate ?
                        <Button className="projectRegisterSubmitBtn" type="primary" loading={this.state.loading}
                                htmlType="submit">
                          更改提交
                        </Button> :
                        <Button className="projectRegisterSubmitBtn" type="primary" loading={this.state.loading}
                                htmlType="submit">
                          提交
                        </Button>}
                    <Button className="projectRegisterBackBtn" onClick={this.backLogin}>
                      返回
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <div className={`${this.state.showRegisterContent ? 'hideContent' : ''}`}
               style={{width: "100%", height: "100%"}}>
            <div className="projectBoxTitle">科技成果评价系统注册</div>
            <div className="projectRegisterSuccessContent">
              <div className="pRSCElementGroup">
                <div className="checkImg">
                  <img src={checkImg} alt="success"/>
                </div>
                <div className="successInfoText">注册成功</div>
                <div className="remindInfoText">您的账号注册成功，点击按钮返回登录页</div>
                <div className="returnButtonRow">
                  <Button className="projectRegisterOkBtn" type="primary" onClick={this.backLogin}>
                    确认
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loginFooter">
          <span className="footerSign">
            {/*©2021 长江技术经济学会 版权所有*/}
            <ul>
              <li><span><a href="https://beian.miit.gov.cn/" target="_blank"
                           rel="noopener noreferrer">鄂ICP备15018540号</a></span><span>
                  <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010202000926"
                     target="_blank"
                     rel="noopener noreferrer">公安备案号42010202000926</a>
                </span><span>长江技术经济学会版权所有</span>
                {/*<span>长江委宣传出版中心制作维护</span>*/}
              </li>
              <li><span>电话/传真：027-82926221</span><span>邮箱：office@cjxh.org.cn</span><span>地址：湖北省武汉市江岸区解放大道1863号</span></li>
            </ul>
          </span>
        </div>
      </div>
    )
  }
}

export default RegisterProject
