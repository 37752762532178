// 获取token值
import {Redirect} from "react-router-dom";
import React from "react";

export function getToken() {
  return localStorage.getItem('ctesToken')
}

// 设置token值
export function setToken(token) {
  return localStorage.setItem('ctesToken', token)
}

// 判定是否登陆
export function isLogin() {
  if (localStorage.getItem('token')) {
    return true
  }
  return false
}

// 获取权限
export function getAuthority() {
  return localStorage.getItem('authority')
}

// 设置权限
export function setAuthority(authority) {
  return localStorage.setItem('authority', authority)
}

// 判定是否有权限值
/**
 * 超级管理员角色返回 1
 * 个人用户及其他返回 2
 * 专家用户返回 4
 * 单位用户返回 5
 * 科技成果评价用户返回 6
 * @returns {string}
 */
export function isAuthority() {
  let authority = JSON.parse(localStorage.getItem('authority'));
  let userType = getUsertype();
  if (userType == "经办人") {
    return "-1"
  } else if (userType == "负责人") {
    return "-2"
  } else if (userType == "专家审定") {
    return "-3"
  }
  if (authority === null) return '3';
  if ((authority.indexOf('超级管理员') !== -1) || (authority.indexOf('admin') !== -1)) {
    return '1'
  } else if (authority.indexOf('专家用户') !== -1) {
    return '4'
  } else if (authority.indexOf('单位用户') !== -1) {
    return '5'
  } else if (authority.indexOf('科技成果评价用户') !== -1) {
    return '6'
  }
  return '2'
}

// 获取用户名
export function getUsername() {
  return localStorage.getItem('ctesUsername')
}

// 设置用户名
export function setUsername(username) {
  return localStorage.setItem('ctesUsername', username)
}

// 获取用户ID
export function getUserid(userid) {
  return localStorage.getItem('ctesUserid')
}


// 设置用户ID
export function setUserid(userid) {
  return localStorage.setItem('ctesUserid', userid)
}

// 获取是否需要加强密码标识
export function getInitPwd() {
  return localStorage.getItem('ctesInitPwd')
}

// 设置是否需要加强密码标识
export function setInitPwd(initPwd) {
  return localStorage.setItem('ctesInitPwd', initPwd)
}

// 获取用户type
export function getUsertype() {
  return localStorage.getItem('ctesType')
}

// 设置用户type
export function setUsertype(type) {
  return localStorage.setItem('ctesType', type)
}

// 获取单位名
export function getGroupName() {
  return localStorage.getItem('groupName')
}

// 设置单位名称
export function setGroupName(name) {
  return localStorage.setItem('groupName', name)
}

// 根据角色筛选出侧边栏路由
export function setSideBar(routes, authority) {
  let routesData = [];
  routes.forEach(item => {
    if (item.authority.find(el => el === authority)) {
      // console.log(item.children)
      if (item.children) {
        item.children = setSideBar(item.children, authority)
      }
      routesData.push(item)
    }
  });
  return routesData
}

// 用户级别判断
export function levelJudge(type, level) {
  let userType = type;
  let userLevel = '';
  if (userType === '0') {
    switch (true) {
      case level === 'H':
        userLevel = '荣誉会员';
        break;
      case level === 'S':
        userLevel = '高级会员';
        break;
      case level === 'M':
        userLevel = '会员';
        break;
      case level === 'A':
        userLevel = '学生会员或青年会员';
        break;
      case level === 'F':
        userLevel = '外籍会员';
        break;
      default:
        userLevel = '会员';
        break;
    }
  } else if (userType === '1') {
    switch (true) {
      case level === 'C':
        userLevel = '常务理事单位';
        break;
      case level === 'F':
        userLevel = '副理事长单位';
        break;
      case level === 'L':
        userLevel = '理事单位';
        break;
      case level === 'P':
        userLevel = '普通会员单位';
        break;
      case level === 'W':
        userLevel = '外籍会员单位';
        break;
      default:
        userLevel = '普通会员单位';
        break;
    }
  } else if (userType === '2') {
    switch (true) {
      case level === 'H':
        userLevel = '荣誉会员';
        break;
      case level === 'S':
        userLevel = '高级会员';
        break;
      default:
        userLevel = '高级会员';
        break;
    }
  }
  return userLevel
}

//清除缓存并退出
export function loginOut() {
  if (localStorage.getItem('ctesToken')) {
    localStorage.removeItem('ctesToken')
  }
  if (localStorage.getItem('authority')) {
    localStorage.removeItem('authority')
  }
  if (localStorage.getItem('ctesUsername')) {
    localStorage.removeItem('ctesUsername')
  }
  if (localStorage.getItem('ctesType')) {
    localStorage.removeItem('ctesType')
  }
  if (localStorage.getItem('groupName')) {
    localStorage.removeItem('groupName')
  }
  let href
  if (localStorage.getItem('loginPage')) {
    href = getElementByLoginPage('/login', '/personLogin', '/expertLogin', '/projectLogin')
    localStorage.removeItem('loginPage')
  }
  window.location.href = href ? href : '/login'
}

//根据登录页返回指定字符串或组件入参
export function getElementByLoginPage(common, person, expert, project) {
  if (localStorage.getItem('loginPage')) {
    let page = localStorage.getItem('loginPage')
    switch (page) {
      case 'common': return common
      case 'person': return person
      case 'expert': return expert
      case 'project': return project
      default: return null
    }
  }
  return null
}