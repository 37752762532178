import React from 'react'
import { Form, Input, Button, Select, Row, Col, notification } from 'antd';
import { sendCaptcha, checkCaptcha } from '../../service/api'
import { UserOutlined, VerifiedOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons';
import './ForgotProject.scss'
import qs from 'qs'

import whiteLogo from '../../assets/images/whiteLogo.png'
import user from '../../assets/images/login/user.png';
import password from '../../assets/images/login/password.png';
import captcha from '../../assets/images/login/captcha.png';
import { checkPassword, checkMobile } from '../../utils/stringUtil'
import {getElementByLoginPage} from "../../utils/auth";


const { Option } = Select;

class ForgotProject extends React.Component {
  state = {
    loading: false,
    formVisible: true,
    username: '',
    roleSelect: '5', //科技成果评价找回密码页固定角色
    time: 60,
    btnDisable: false,
    btnContent: '获取验证码',
  };

  // 跳转至登录页面
  toLogin = e => {
    e.stopPropagation();
    const history = getElementByLoginPage('/login', '/personLogin', '/expertLogin', '/projectLogin')
    this.props.history.push(history)
  };

  // 提交表单
  submit = (e) => {
    e.role = "5" //科技成果评价找回密码表单提交固定角色
    const params = qs.stringify({
      'username': e.username
      , 'role': e.role
      , 'captcha': e.captcha
      , 'password': e.password
    });
    checkCaptcha(params).then(res => {

      if (200 === res.data.code) {
        this.setState({
          formVisible: false
        })
      } else if (4002 === res.data.code) {
        return notification.error({
          message: '提示',
          description: '验证码有误或已失效，请重试！'
        })
      } else {
        return notification.error({
          message: '提示',
          description: '操作失败，请联系管理员！'
        })
      }

    })

  };

  usernameChange = e => {
    this.setState({
      username: e.target.value
    })
  };

  selectChange = e => {
    this.setState({
      roleSelect: e
    })
  };

  render() {
    let timeChange;
    let ti = this.state.time;
    //关键在于用ti取代time进行计算和判断，因为time在render里不断刷新，但在方法中不会进行刷新
    const clock = () => {
      if (ti > 0) {
        //当ti>0时执行更新方法
        ti = ti - 1;
        this.setState({
          time: ti,
          btnContent: `稍候(${ti}s)`,
        });

      } else {
        //当ti=0时执行终止循环方法
        clearInterval(timeChange);
        this.setState({
          btnDisable: false,
          time: 60,
          btnContent: "获取验证码",
        });
      }
    };

    const getCaptcha = () => {
      if (null == this.state.username || '' === this.state.username
        || null == this.state.roleSelect || '' === this.state.roleSelect) {
        return notification.warning({
          message: '提示',
          description: '请填写用户名及登录角色后，再获取验证码！'
        })
      }

      //向后台发送请求
      const params = qs.stringify({
        'username': this.state.username
        , 'role': this.state.roleSelect
      });
      sendCaptcha(params).then(res => {

        if (200 === res.data.code) {
          notification.success({
            message: '提示',
            description: `验证码已发送到${res.data.data}，请查收！`
          });
          this.setState({
            btnDisable: true,
            btnContent: "稍候(60s)",
          });
          //每隔一秒执行一次clock方法
          timeChange = setInterval(clock, 1000);
        } else if (2007 === res.data.code) {
          return notification.error({
            message: '提示',
            description: '用户名或角色有误，请查证后再操作！'
          })
        } else if (2015 === res.data.code) {
          return notification.error({
            message: '提示',
            description: '手机号校验有误，请尝试使用身份证号找回密码！'
          })
        } else if (4003 === res.data.code) {
          return notification.error({
            message: '提示',
            description: '短信发送失败！'
          })
        } else {
          return notification.error({
            message: '提示',
            description: '操作失败，请联系管理员！'
          })
        }

      })

    };


    return (
        <div className="forgotProject">
          <div className="loginCenter">
            <div className="loginLog">
              <img className="logoImage" src={whiteLogo} alt="logo"/>
            </div>
            <div className="loginBox">
              <div className="loginElementGroup">
                <div className="loginHeader">
                  <div className='title'>找回密码</div>
                  {/* <div className='subTitle'>本平台为互联网非涉密平台，严禁处理、传输国家秘密</div> */}
                </div>
                <div className="loginContent">
                  {this.state.formVisible ?
                      <Form
                          name="normal_login"
                          className="login-form"
                          onFinish={this.submit}
                      >
                        <div className="contentTitle">
                          <img className="site-form-item-icon" src={user} alt="user"/>
                          <div className="text">手机号</div>
                        </div>
                        <Form.Item
                            name="username"
                            rules={this.state.roleSelect != "3" ? [
                              {
                                validator: checkMobile
                              },
                              {required: true, message: '请输入用户名!'}
                            ] : [{required: true, message: '请输入统一信用代码!'}]}
                        >
                          <Input value={this.state.username} onChange={this.usernameChange}
                                 placeholder={this.state.roleSelect != "3" ? "手机号" : "联系人手机号或单位统一信用代码"}/>
                        </Form.Item>

                        <div className="contentTitle">
                          <img className="site-form-item-icon" src={captcha} alt="captcha"/>
                          <div className="text">手机验证码</div>
                        </div>
                        <Form.Item
                            name="captcha"
                            rules={[{required: true, message: '请输入验证码!'}]}
                        >
                          <Row className='captchaBox'>
                            <Input className='captchaInput'
                                   placeholder="请输入验证码"/>
                            <Button
                                className='captchaButton'
                                onClick={getCaptcha}
                                disabled={this.state.btnDisable}
                            >
                              {this.state.btnContent}
                            </Button>
                          </Row>
                        </Form.Item>

                        <div className="contentTitle">
                          <img className="site-form-item-icon" src={password} alt="password"/>
                          <div className="text">密码</div>
                        </div>
                        <Form.Item
                            name="password"
                            rules={[
                              {
                                validator: checkPassword
                              },
                              {required: true, message: '请输入新密码!'}
                            ]}
                        >
                          <Input
                              type="password"
                              placeholder="请输入新密码"
                          />
                        </Form.Item>

                        <div className="contentTitle">
                          <img className="site-form-item-icon" src={password} alt="password"/>
                          <div className="text">确认密码</div>
                        </div>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: '请输入确认密码!',
                              },
                              ({getFieldValue}) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(new Error('两次密码输入不一致!'));
                                },
                              }),
                            ]}
                        >
                          <Input
                              className="confirmPasswordInput"
                              type="password"
                              placeholder="请确认密码"
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button htmlType="submit" className="login-form-button">
                            提交
                          </Button>
                        </Form.Item>
                      </Form>
                      :
                      <span>密码修改成功</span>
                  }
                </div>
                <div className="loginOther">
                  <div className="registerBtn"></div>
                  <div className="registerBtn"><span onClick={e => this.toLogin(e)}>去登陆</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

}

export default ForgotProject
