import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Loadable from './service/loadable'
// import { adminRoutes } from './routes'
import ChangePwd from './components/changePwd/index'
import Frame from './layout/index.jsx'
import MemberFrame from './memberlayout/index.jsx'
import ExpertsFrame from './expertsLayout/index.jsx'
import GroupFrame from './groupLayout/index.jsx'
import ProjectFrame from './projectLayout/index.jsx'
// 判定是否登陆时使用isLogin和isAuthority, 解开登陆所需判断，使用三元表达式
import {isAuthority, getInitPwd, getElementByLoginPage} from './utils/auth'
import './App.css';
import locale from 'antd/lib/locale/zh_CN'
import { ConfigProvider, notification } from 'antd';

// 使用Loadable更快加载
const Dashboard = Loadable(() => import('./pages/AdminPages/Dashboard/Dashboard'));
const Administrators = Loadable(() => import('./pages/AdminPages/Administrators/Administrators'));
const Experts = Loadable(() => import('./pages/AdminPages/Personmanger/Experts/Experts'));
const Personmember = Loadable(() => import('./pages/AdminPages/Personmanger/Personmember/Personmember'));
const Directormember = Loadable(() => import('./pages/AdminPages/Personmanger/Directormember/Directormember'));
const Unitmember = Loadable(() => import('./pages/AdminPages/Personmanger/Unitmember/Unitmember'));
const Projectmember = Loadable(() => import('./pages/AdminPages/Personmanger/Projectmember/Projectmember'));
// const Error = Loadable(() => import('./pages/Error/404'))
const Memberhome = Loadable(() => import('./pages/MemberPages/Myhome/Myhome'));
const Projecthome = Loadable(() => import('./pages/MemberPages/Myhome/ProjectMyhome'));
const Memberbaseinfo = Loadable(() => import('./pages/MemberPages/Myinfo/Myinfo'));
const Logininfo = Loadable(() => import('./pages/MemberPages/LogInfo/Loginfo'));
const ProjectLogininfo = Loadable(() => import('./pages/MemberPages/LogInfo/ProjectLoginfo'));
const Memberopinion = Loadable(() => import('./pages/MemberPages/Opinioninfo/Opinioninfo'));
const Updatepwd = Loadable(() => import('./pages/MemberPages/Updatepwd/Updatepwd'));
const ProjectUpdatepwd = Loadable(() => import('./pages/MemberPages/Updatepwd/ProjectUpdatepwd'));
const Examine = Loadable(() => import('./pages/MemberPages/Examine/Examine'));
const ScienceVideo = Loadable(() => import('./pages/MemberPages/ScienceVideo'));
const CloudForum = Loadable(() => import('./pages/MemberPages/CloudForum'));
const GroupMember = Loadable(() => import('./pages/MemberPages/GroupMember/GroupMember'));
const OutBox = Loadable(() => import('./pages/AdminPages/OutBox/OutBox'));
const SMSOutBox = Loadable(() => import('./pages/AdminPages/SMSOutBox/SMSOutBox'));
const UnitChart = Loadable(() => import('./pages/AdminPages/UnitChart/UnitChart'));
const PersonChart = Loadable(() => import('./pages/AdminPages/PersonChart/PersonChart'));
const VideoManage = Loadable(() => import('./pages/AdminPages/VideoManage/Video'));
const ArticleManage = Loadable(() => import('./pages/AdminPages/ArticleManage/Article'));
const KjcgManage = Loadable(() => import('./pages/AdminPages/KjcgManage/Kjcg'));
const Kjcgofexpert = Loadable(() => import('./pages/AdminPages/KjcgManage/Kjcgofexpert'));
const KjcgOriginManage = Loadable(() => import('./pages/AdminPages/KjcgManage/KjcgOrigin'));
const Abnormal = Loadable(() => import('./pages/AdminPages/Abnormal/Abnormal'))
const BeenSend = Loadable(() => import('./pages/AdminPages/BeenSend/BeenSend'));
const SMSBeenSend = Loadable(() => import('./pages/AdminPages/SMSBeenSend/SMSBeenSend'));
const DemoInfo = Loadable(() => import('./pages/AdminPages/information/DemoInfo'));
const ModalInfo = Loadable(() => import('./pages/AdminPages/information/ModalInfo'));
const OrgIndex = Loadable(() => import('./pages/AdminPages/Org/OrgIndex'));

const isModalVisible = getInitPwd() === 'true' || getInitPwd() === undefined

class App extends React.Component {
  state = {
  };

  componentDidMount() {
    if (getInitPwd() === 'true' || getInitPwd() === undefined) {
      notification.warning({
        message: '您当前正在使用弱密码或默认密码，为保障您的账号安全请先修改密码!',
      })
    }
  }

  render() {
    const commonRouteList = (
      <>
        <Route path="/memberpages/xsjl" component={ScienceVideo} key="xsjl"></Route>
        <Route path="/memberpages/kjjl" component={ScienceVideo} key="kjjl"></Route>
        <Route path="/memberpages/ttbz" component={ScienceVideo} key="ttbz"></Route>
        <Route path="/memberpages/kxpj" component={ScienceVideo} key="kxpj"></Route>
        {isAuthority() === '2' ? <Route path="/memberpages/kjcg" component={KjcgOriginManage}></Route> : isAuthority() === '4' ? <Route path="/memberpages/kjcgofexpert" component={Kjcgofexpert}></Route> : ""}
      </>
    )
    if (isAuthority() === '1') {
      return (
        <Frame>
          <Switch>
            <Route path="/adminpages/dashboard" component={Dashboard}></Route>
            <Route path="/adminpages/administrators" component={Administrators}></Route>
            <Route path="/adminpages/experts" component={Experts}></Route>
            <Route path="/adminpages/personmember" component={Personmember}></Route>
            <Route path="/adminpages/directormember" component={Directormember}></Route>
            <Route path="/adminpages/unitmember" component={Unitmember}></Route>
            <Route path="/adminpages/OutBox" component={OutBox}></Route>
            <Route path="/adminpages/SMSOutBox" component={SMSOutBox}></Route>
            <Route path="/adminpages/UnitChart" component={UnitChart}></Route>
            <Route path="/adminpages/PersonChart" component={PersonChart}></Route>
            {/* <Route path="/adminpages/videos" component={VideoManage}></Route> */}
            <Route path="/adminpages/xsjl" component={ArticleManage} key="xsjl"></Route>
            <Route path="/adminpages/kjjl" component={ArticleManage} key="kjjl"></Route>
            <Route path="/adminpages/ttbz" component={ArticleManage} key="ttbz"></Route>
            <Route path="/adminpages/kxpj" component={ArticleManage} key="kxpj"></Route>
            <Route path="/adminpages/kjcg" component={KjcgOriginManage}></Route>
            <Route path="/adminpages/abnormal" component={Abnormal}></Route>
            <Route path="/adminpages/BeenSend" component={BeenSend}></Route>
            <Route path="/adminpages/SMSBeenSend" component={SMSBeenSend}></Route>
            <Route path="/adminpages/information/demo" component={DemoInfo}></Route>
            <Route path="/adminpages/information/ModalInfo" component={ModalInfo}></Route>
            <Route path="/adminpages/org/orgIndex" component={OrgIndex}></Route>
            <Route path="/adminpages/projectmember" component={Projectmember}></Route>
            {localStorage.getItem('loginPage') === 'project' ? <Redirect to="/memberpages/kjcg" from="/adminpages"/> : <Redirect to="/adminpages/dashboard" from="/adminpages"/>}
          </Switch>
        </Frame>
      )
    } else if (isAuthority() === '-1' || isAuthority() === '-2' || isAuthority() === '-3') {
      //经办人和负责人只能看到科技成果评价
      return (
        <Frame>
          <Switch>
            <Route path="/adminpages/kjcg" component={KjcgOriginManage}></Route>
          </Switch>
        </Frame>
      )
    } else if (isAuthority() === '2') {
      return (
        <MemberFrame>
          <Switch>
            <Route path="/memberpages/home" component={Memberhome}></Route>
            <Route path="/memberpages/baseinfo" component={Memberbaseinfo}></Route>
            <Route path="/memberpages/logininfo" component={Logininfo}></Route>
            <Route path="/memberpages/opinioninfo" component={Memberopinion}></Route>
            <Route path="/memberpages/updatepwd" component={Updatepwd}></Route>
            {commonRouteList}
            {/* <Route path="/memberpages/cloudforum" component={CloudForum}></Route> */}
            <Redirect to="/memberpages/baseinfo" from="/memberpages" />
          </Switch>
          <ChangePwd isModalVisible={isModalVisible} />
        </MemberFrame>
      )
    } else if (isAuthority() === '4') {
        //专家登录时判断登录入口
        return localStorage.getItem('loginPage') === "project" ?
      (
        <ProjectFrame>
          <Switch>
            <Route path="/memberpages/home" component={Memberhome}></Route>
            <Route path="/memberpages/logininfo" component={Logininfo}></Route>
            <Route path="/memberpages/kjcg" component={Kjcgofexpert}></Route>
            <Route path="/memberpages/updatepwd" component={Updatepwd}></Route>
            <Redirect to="/memberpages/home" from="/memberpages" />
          </Switch>
          <ChangePwd isModalVisible={isModalVisible} />
        </ProjectFrame>
      ) :
      (
        <ExpertsFrame>
          <Switch>
            <Route path="/memberpages/home" component={Memberhome}></Route>
            <Route path="/memberpages/baseinfo" component={Memberbaseinfo}></Route>
              <Route path="/memberpages/logininfo" component={Logininfo}></Route>
              <Route path="/memberpages/opinioninfo" component={Memberopinion}></Route>
              <Route path="/memberpages/updatepwd" component={Updatepwd}></Route>
              <Route path="/memberpages/examine" component={Examine}></Route>
              {commonRouteList}
              {/* <Route path="/memberpages/cloudforum" component={CloudForum}></Route> */}
              <Redirect to="/memberpages/baseinfo" from="/memberpages" />
          </Switch>
          <ChangePwd isModalVisible={isModalVisible} />
        </ExpertsFrame>
      )
    } else if (isAuthority() === '5') {
      return (
        <GroupFrame>
          <Switch>
            <Route path="/memberpages/home" component={Memberhome}></Route>
            <Route path="/memberpages/baseinfo" component={Memberbaseinfo}></Route>
            <Route path="/memberpages/logininfo" component={Logininfo}></Route>
            <Route path="/memberpages/groupmember" component={GroupMember}></Route>
            <Route path="/memberpages/updatepwd" component={Updatepwd}></Route>
            {commonRouteList}
            {/* <Route path="/memberpages/cloudforum" component={CloudForum}></Route> */}
            <Redirect to="/memberpages/baseinfo" from="/memberpages" />
          </Switch>
          <ChangePwd isModalVisible={isModalVisible} />
        </GroupFrame>
      )
    } else if (isAuthority() === '6') {
      return (
          <ProjectFrame>
            <Switch>
                <Route path="/memberpages/home" component={Projecthome}></Route>
                <Route path="/memberpages/baseinfo" component={Memberbaseinfo}></Route>
                <Route path="/memberpages/kjcg" component={KjcgManage}></Route>
                <Route path="/memberpages/logininfo" component={ProjectLogininfo}></Route>
                <Route path="/memberpages/updatepwd" component={ProjectUpdatepwd}></Route>
                <Redirect to="/memberpages/kjcg" from="/memberpages" />
            </Switch>
            <ChangePwd isModalVisible={isModalVisible} />
          </ProjectFrame>
      )
    } else {
      return (getElementByLoginPage(<Redirect to="/login" />, <Redirect to="/personLogin" />, <Redirect to="/expertLogin" />, <Redirect to="/projectLogin" />))
    }
  }
}

let NewApp = () => (
  <ConfigProvider locale={locale} notification={notification.config({ maxCount: 1 })}>
    <App />
  </ConfigProvider>
);
export default NewApp
